const variantName = 'openingPageV1'

export default {
  [variantName]: {
    container: {
      width: '100%',
      height: '100vh',
      position: 'fixed',
      top: '0rem',
      left: '0rem',
      zIndex: '8000',
      backgroundColor: 'light',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      transition: 'all ease-in-out 1s',
      animationName: 'hideSection',
      animationDuration: '2s',
      animationIterationCount: '1',
      animationTimingFunction: 'ease',
      animationFillMode: 'forwards',

      '@keyframes hideSection': {
        '0%': {
          opacity: '1',
        },
        '80%': {
          opacity: '1',
        },

        '95%': {
          opacity: '0',
          top: '0vh',
        },

        '100%': {
          opacity: '0',
          top: '200vh',
        },
      },

      '@keyframes logoAnimateIn': {
        'from': {
          opacity: '0',
        },

        'to': {
          opacity: '1',
        },
      },
    },
    content: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      img: {
        opacity: '1',
        maxWidth: '300px',
        animationName: 'logoAnimateIn',
        animationDuration: '3s',
      },
    },
  },
}
