const variantName = 'footerV6'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      paddingX: [4, '', '', 4],
      paddingY: 10,
      backgroundColor: 'dark',
      color: '#bcbcbc',
      flexDirection: ['column', '', '', 'row'],
      flexWrap: 'wrap',
      justifyContent: ['', '', '', 'center'],
      alignItems: ['center', '', '', 'flex-start'],
      // ?== Nested Elements ==
      '.multiButtonContainer': {
        margin: '0rem 0rem',
        width: '100%',
        a: {
          width: ['', '', '', '50%'],
          color: '#bcbcbc',
          margin: ['0.25rem', '', '', '0rem'],
          border: 'none',
          textAlign: ['', '', '', 'left'],
          padding: ['', '', '', '0.75rem 0rem'],
          ':hover': {
            backgroundColor: 'transparent',
            opacity: '0.5',
          },
        },
      },
      '> div:nth-of-type(1)': {
        borderRight: ['', '', '', '1px solid'],
        padding: ['', '', '', '2rem 1rem 2rem 2rem'],
      },
      '.contactDetails-container': {
        textAlign: ['center', '', '', 'left'],
        '.text': {
          margin: ['', '', '', '0.5rem 0rem'],
          fontSize: '1rem',
        },
      },
      '.socialContainer': {
        margin: ['1rem', '', '', '1rem 0rem'],
        svg: {
          marginRight: '0.5rem',
          width: '25px',
          height: '25px',
        },
      },
    },

    column: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['center', '', '', 'flex-start'],
      width: ['', '', '', '25%'],
      padding: ['1rem 0rem', '', '2rem'],
      textAlign: 'left',
      marginBottom: ['', '', '', '1rem'],
      maxWidth: '350px',
    },

    // ?============================
    // ?=========  Texts  ==========
    // ?============================

    sectionHeading: {
      marginBottom: '1rem',
      paddingBottom: '1rem',
      borderBottom: '1px solid #bcbcbc',
    },

    aboutText: {
      color: '#bcbcbc',
      textAlign: ['center', '', '', 'left'],
    },

    ctaButton: {
      variant: 'buttons.primary',
      marginTop: '1rem',
      padding: '0.5rem 1rem',
      color: '#bcbcbc',
      borderColor: '#bcbcbc',
      borderRadius: '30px',
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logo: {
      maxHeight: '150px',
    },

    // ?============================
    // ?=========  Widgets  ========
    // ?============================

    quote: {
      margin: ['0rem auto', '', '', '0rem'],
      fontSize: '1rem',
      textAlign: ['center', '', '', 'left'],
      padding: '1rem 0rem',
      color: '#bcbcbc',
    },

    poweredBy: {
      margin: '0rem 0rem 1rem',
      flexDirection: 'column',
      display: 'flex',
      alignItem: 'center',
      '.gonationLogo': {
        justifyContent: ['center', '', '', 'flex-start'],
        alignItems: 'center',
        display: 'flex',
        svg: {
          height: '1.25rem',
          width: 'auto',
        },
        filter: 'brightness(0) invert(1)',
        margin: '0rem',
        maxWidth: 'unset !important',
      },
    },
    poweredByText: {
      fontSize: '0.9rem',
    },
  },
}
