export default {
  primary: {
    borderTop: 'solid 2px',
    borderBottom: '2px solid',
    borderColor: 'secondary',
    backgroundColor: 'transparent',
    color: 'text',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '1px',
    borderLeft: 'none',
    borderRight: 'none',
    borderRadius: '0px',
    ':hover': {
      backgroundColor: 'secondary',
    },
  },
  secondary: {
    borderTop: 'solid 2px',
    borderBottom: '2px solid',
    borderColor: 'primary',
    backgroundColor: 'secondary',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '1px',
    borderLeft: 'none',
    borderRight: 'none',
    borderRadius: '0px',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'secondary',
    },
  },
}
