const variantName = 'navigationV11'

export default {
  [variantName]: {
    // ?============================
    // ?======== Container =========
    // ?============================

    container: {
      position: 'fixed',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      // height: ['75px', '75px', '75px', 'unset'],
      padding: ['0.5rem', '', '', '2rem 1rem'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'light',
      '.seperatorLine': {
        margin: ['1rem auto 2rem', '', '', '1rem 0 2rem auto'],
        width: '30%',
        maxWidth: '600px',
      },
    },
    containerScrolled: {
      variant: `${variantName}.container`,
      height: ['90px', '90px', '90px', 'unset'],
      padding: ['0.5rem', '', '', '0.75rem'],
      backgroundColor: 'black',
    },

    // ?============================
    // ?=======  Nav Logos =========
    // ?============================

    logoLocationContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0.25rem 1rem',
      height: '100%',
      textAlign: 'center',
      display: ['inline-block', '', '', ''],
    },

    logo: {
      zIndex: '2000',
      transition: 'all ease-in-out 0.4s',
      justifyContent: 'center',

      img: {
        opacity: '0.85',
        maxHeight: ['100px'],
        ':hover': {
          opacity: '1',
        },
      },
    },
    logoScrolled: {
      variant: `${variantName}.logo`,
      maxHeight: ['100%'],
      height: ['100%'],
      display: ['inline-block', '', '', 'inline'],
      textAlign: 'center',
      img: {
        height: ['100%'],
        maxHeight: ['100%', '100px'],
        padding: 0,
        opacity: '0.85',
        ':hover': {
          opacity: '1',
        },
      },
    },

    navMenuLogo: {
      img: {
        maxHeight: ['120px', '', '', 'unset'],
      },
      marginBottom: ['1rem', '', '', '0rem'],
      position: ['', '', '', 'absolute'],
      left: ['', '', '', '2rem'],
      width: ['', '', '', '40%'],
    },

    // ?============================
    // ?=======  Hamburger =========
    // ?============================

    hamburger: {
      display: ['flex', 'flex', 'flex', ''],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: ['0 0.5rem 0 auto', '', '', '0rem 0.75rem'],
      minWidth: '30px',
      position: 'absolute',
      right: '0',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'light',
        borderRadius: '10px',
      },
    },
    hamburgerOpen: {
      variant: `${variantName}.hamburger`,
      height: 'unset',
      div: {
        position: 'absolute',
        boxShadow: 'none',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px',
      },
      '& > div:nth-of-type(1)': {
        transform: 'rotate(135deg) ',
      },
      '& > div:nth-of-type(2)': {
        transform: 'rotate(-135deg)',
      },
    },

    // ?============================
    // ?========  Nav Menu =========
    // ?============================

    navMenu: {
      position: ['absolute', 'absolute', 'absolute'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['center', 'center', 'center'],
      backgroundColor: ['dark', 'dark', 'dark'],
      top: '0px',
      zIndex: '1000',
      width: ['100%', '100%', '100%', '100%'],
      height: '100vh',
      minWidth: '250px',
      left: '-120%',
      transition: 'all ease-in-out 0.5s',
      padding: ['1rem', '', '', '2rem'],
      alignItems: ['center', 'center', 'center', 'flex-end'],
      textAlign: 'center',
      display: 'flex',
      flexDirection: ['column', 'column', 'column'],
      overflow: 'hidden',
      listStyleType: 'none',
    },

    // when nav is open it uses this variant
    navMenuOpen: {
      variant: `${variantName}.navMenu`,
      left: '0%',
    },

    navItem: {
      margin: ['0.5rem', '0.75rem', '', '0.5rem'],
      zIndex: '100',
      listStyleType: 'none',
      a: {
        fontFamily: 'heading',
        fontSize: ['1.2rem', '', '', '2rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        letterSpacing: '2px',
        textDecoration: 'none',
        ':hover': {
          color: 'secondary',
        },
      },
    },

    smallNavMenuLeft: {
      display: ['none', '', '', 'flex'],
      justifyContent: 'flex-end',
      flex: '1',
      '> div': {
        borderTop: 'solid 1px',
        borderBottom: 'solid 1px',
        borderColor: 'rgb(255 255 255 / 50%)',
      },
      '.navItem a': {
        fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem'],
      },
    },

    smallNavMenuRight: {
      display: ['none', '', '', 'flex'],
      justifyContent: 'flex-start',
      flex: '1',
      '> div': {
        borderTop: 'solid 1px',
        borderBottom: 'solid 1px',
        borderColor: 'rgb(255 255 255 / 50%)',
      },
      '.navItem a': {
        fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem'],
      },
    },

    smallNavHeading: {
      fontSize: '1rem',
      marginBottom: '0.5rem',
      fontWeight: '400',
    },

    // ?==========================
    // ?====  Dropdown Styles ====
    // ?==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative',
      cursor: 'pointer',
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap',
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      position: ['', '', '', 'absolute'],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: 'primary',
    },

    // ?==========================
    // ?=====  Nav Widgets  ======
    // ?==========================

    locationSwitcher: {
      backgroundColor: '',
      zIndex: '2000',
      alignSelf: 'flex-start',
      marginTop: '0.5rem',
      button: {
        backgroundColor: 'unset',
        borderTop: '1px solid',
        borderRadius: '0px',
      },
      '.popUp': {
        transform: ['', '', '', 'translateX(-45%)'],
      },
      '.popUp .content': {
        backgroundColor: 'rgb(255 255 255 / 74%)',
      },
    },

    socialIconsContainerDesktop: {
      display: ['none', '', '', 'flex'],
      padding: '0.5rem 1rem',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      margin: ['', '', '', '0 auto 0 0'],
      a: {
        display: 'flex',
        textAlign: 'center',
        padding: '0.5rem',
        alignItems: 'center',
        transition: 'all ease-in-out 0.5s',
        ':hover': {
          transform: 'scale(1.5)',
          svg: {
            path: { fill: 'secondary' },
          },
        },
      },
    },

    phoneContainerDesktop: {
      display: ['none', '', '', 'block'],
      borderRight: 'solid 1px',
      borderColor: 'secondary',
      paddingRight: '0.75rem',
      a: {
        transition: 'all ease-in-out 0.5s',
        ':hover': {
          color: 'secondary',
          fontSize: '1.1rem',
        },
      },
    },

    phoneSocialContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: ['center', '', '', 'flex-end'],
      flexDirection: ['row', '', '', 'column'],
      width: '100%',
      maxWidth: '450px',
    },

    phoneContainer: {
      padding: '0.5rem',
      width: 'calc(50% - 1rem)',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      border: '1px solid',
      borderColor: 'light',
      margin: ['0.5rem', '', '', '0.5rem 0rem'],
      a: {
        display: 'flex',
        padding: '0.5rem',
        alignItems: 'center',
        fontSize: '0.8rem',
        textAlign: 'center',
        justifyContent: 'center',
        svg: {
          marginRight: '0.25rem',
        },
      },
    },

    socialIconsContainer: {
      padding: '0.5rem',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'calc(50% - 1rem)',
      textAlign: 'center',
      border: '1px solid',
      borderColor: 'light',
      margin: ['0.5rem', '', '', '0.5rem 0rem'],
      a: {
        display: 'flex',
        textAlign: 'center',
        padding: '0.5rem',
        alignItems: 'center',
      },
    },
  },
}
