export default {
  // ? ===   Layout  ====

  navigation: {
    '.container': {
      padding: ['0.5rem', '', '', '2rem'],
      height: '75px',
    },
    '.containerScrolled': {
      backgroundColor: 'white',
      color: 'text',
      height: '75px',
      '.smallNavMenuRight > div, .smallNavMenuLeft > div': {
        borderColor: 'text',
        border: 'none',
      },
      '.hamburger div': { backgroundColor: 'dark' },
      borderBottom: '2px solid',
      borderColor: 'primary',
    },
    '.navMenuOpen .seperatorLine': {
      margin: [
        '2rem auto',
        '2rem auto',
        '2rem auto',
        '2rem auto',
      ],
    },
    '.hamburger': {
      right: '1rem',
    },

    '.navMenuOpen': {
      backgroundColor: 'white',
      alignItems: ['center', 'center', 'center', 'center'],
      variant: 'customVariants.italianBorderTopBottom',
    },
    '.smallNavMenu': {
      flex: 'unset',
    },

    '.smallNavMenuRight > div, .smallNavMenuLeft > div': {
      borderTop: 'none',
    },
    '.smallNavMenu .navItem': {
      margin: ['', '', '', '0.75rem 1rem', '1rem 1.5rem'],
    },
    '.logoLocationContainer': { display: 'none' },

    '.navMenuLogo': {
      display: 'none',
    },
    '.phoneSocialContainer': {
      flexDirection: ['row', 'row', 'row', 'row'],
      'svg path': {
        fill: 'dark',
      },
    },
  },

  footer: {
    borderTop: '4px solid',
    borderColor: 'secondary',
    backgroundColor: '#ffffff',
    '.multiButtonContainer': {
      flexDirection: ['', '', '', 'column'],
    },

    '.sectionHeading': {
      color: 'secondary',
      textTransform: 'uppercase',
      fontSize: '1.5rem',
    },
    '.logo': {
      borderBottom: '1px solid',
      borderColor: 'secondary',
      paddingBottom: '1rem',
      marginBottom: '1rem',
    },
    '.quote': {
      fontFamily: 'display',
      color: 'secondary',
      fontWeight: 'bold',
    },

    '.ctaButton': {
      variant: 'buttons.primary',
      marginTop: '1rem',
    },
    '.socialContainer svg path': {
      fill: 'primary',
    },
    '.poweredBy a': {
      filter: 'brightness(3)',
    },
  },

  ctaWidget: {},

  // ? ===   Repeat Styles  ====

  italianBorderTopBottom: {
    '::before': {
      content: "''",
      display: 'flex',
      borderRadius: '252px 55px 104px 20px / 34px',
      position: 'relative',
      background:
        'linear-gradient(90deg, rgb(51, 180, 76) 0%, rgb(235 235 235) 33%, rgb(235 235 235) 66%, rgb(217, 32, 39) 100%)',
      height: '5px',
      left: '0px',
      opacity: '0.7',
      marginBottom: '1rem',
      width: '100%',
    },
    '::after': {
      content: "''",
      display: 'flex',
      borderRadius: '252px 55px 104px 20px / 34px',
      position: 'relative',
      background:
        'linear-gradient(90deg, rgb(51, 180, 76) 0%, rgb(235 235 235) 33%, rgb(235 235 235) 66%, rgb(217, 32, 39) 100%)',
      height: '5px',
      left: '0px',
      opacity: '0.7',
      marginTop: '1rem',
      width: '100%',
    },
  },

  italianBorderBottom: {
    '::after': {
      content: "''",
      display: 'flex',
      borderRadius: '252px 55px 104px 20px / 34px',
      position: 'relative',
      background:
        'linear-gradient(90deg, rgb(51, 180, 76) 0%, rgb(235 235 235) 33%, rgb(235 235 235) 66%, rgb(217, 32, 39) 100%)',
      height: '5px',
      left: '0px',
      opacity: '0.7',
      marginTop: '0.5rem',
      width: '100%',
    },
  },

  // ? ===   Homepage  ====

  homepageHero: {
    height: ['70vh'],
    '.hero_content_container': {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      maxWidth: '767px',
      width: '90%',
      // variant: 'customVariants.italianBorderTopBottom',
    },

    '.title': {
      fontSize: ['2rem', '2.5rem', '4rem', '5rem'],
      textTransform: 'uppercase',
      textShadow: '3px 1px #000000',
      color: 'white',
    },

    '.subtitle': {
      color: '#ffd6b2',
    },

    '.ctaButton': {
      variant: 'buttons.primary',
    },

    '.slick-next': {
      bottom: '1rem',
      left: '3rem',
      top: 'unset',
    },
    '.slick-prev': {
      bottom: '1rem',
      left: '1.5rem',
      top: 'unset',
    },
    '.slick-slide': {
      img: {
        filter: 'brightness(0.8)',
        height: ['70vh'],
      },
    },

    '.slick-initialized .slick-slide > div': {
      height: ['70vh'],
    },
  },
  homepageHeroShout: {
    display: 'none',
  },

  homepagePremierPizza: {
    position: 'relative',
    '.section': {
      maxWidth: 'unset',
    },
    '.title': {
      color: 'primary',
      textTransform: 'uppercase',
      fontSize: ['3rem', '', '6rem'],
      textShadow: '1px 2px 2px #bdbdbd',
    },
    '.subtitle': {
      color: 'secondary',
      fontSize: ['1.5rem', '', '3rem'],
      variant: 'customVariants.italianBorderBottom',
      // borderColor: '#c4c4c4',
      paddingBottom: '0rem',
    },
    '.text': {},
    '.ctaButton': {},
  },

  homepageShout: {
    flexDirection: ['column', 'column', 'row'],
    '.imageContainer': {
      width: ['100%', '100%', '40%'],
    },
    '.image': {
      padding: ['0rem', '', '1rem', '2rem'],
      objectFit: 'contain',
    },
    '.content': {
      backgroundColor: ['', '', '#f8f8f8'],
      color: 'text',
      margin: ['', '', '2rem 1rem'],
      border: 'solid 2px',
      borderColor: 'primary',
      borderRight: 'none',
      width: ['100%', '100%', '60%'],
    },
    '.text': {
      lineHeight: '1.5',
      color: 'primary',
      fontSize: '2.5rem',
      letterSpacing: '1px',
    },
    '.title': {
      width: ['3rem', '', '', '4rem'],
      textTransform: 'uppercase',
      color: 'text',
    },

    '.shoutCTA': {
      variant: 'buttons.primary',
    },
  },

  homepageAboutSection: {
    position: 'relative',
    '.content': {
      alignItems: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
      ],
      textAlign: ['left', 'left', 'left', 'left'],
    },
    '.title': {
      fontWeight: '300',
      variant: 'customVariants.italianBorderBottom',
    },
    '.subtitle': {
      fontWeight: 'bold',
      color: 'primary',
    },
    '.text': {},

    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  homepageMenuSection: {
    position: 'relative',
    '.content': {
      alignItems: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
      ],
      textAlign: ['left', 'left', 'left', 'left'],
    },
    '.title': {
      fontWeight: '300',
      variant: 'customVariants.italianBorderBottom',
    },
    '.subtitle': {
      fontWeight: 'bold',
      color: 'primary',
    },
    '.text': {},

    '.lazyload-wrapper': {
      '.image': {
        height: ['100%', '100%', '100%', '100%'],
      },
    },

    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  homepageGallerySection: {
    '.content': {
      padding: ['1rem', '', '1.5rem', '4rem'],
    },
    '.title': {
      textShadow: '2px 3px grey',
    },
    '.subtitle': {
      fontFamily: 'display',
      color: 'secondary',
      marginBottom: '1.5rem',
      fontSize: '1.75rem',
    },
    '.ctaButton': {
      variant: 'buttons.primary',
    },
  },

  homepageContactSection: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    color: 'white',
    padding: '10rem 1rem',
    '.section': {
      alignItems: 'flex-start',
      textAlign: 'left',
      margin: '0rem 0rem 0rem 5%',
      maxWidth: 'unset',
    },
    '.title': {
      textAlign: 'left',
      borderLeft: 'none',
      paddingLeft: '0rem',
      textTransform: 'uppercase',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
      variant: 'customVariants.italianBorderBottom',
    },
    '.subtitle': {
      fontFamily: 'display',
      color: 'secondary',
      marginBottom: '1rem',
      textAlign: 'left',
      background: 'rgb(255 255 255 / 63%)',
      padding: ['1rem 0.75rem'],
      borderRadius: '100px',
      position: 'relative',
      left: '-1rem',
      fontSize: ['1.25rem', '1.5rem'],
    },
    '.text': {
      color: 'white',
      textAlign: 'left',
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      color: 'white',
    },
  },

  homepageReviews: {
    '.menuSectionTitle': {
      fontSize: ['2rem', '3.5rem'],
    },
    '.menuSectionDescription': {
      color: 'primary',
    },
    '.menuItemContentContainer': {
      padding: '1rem',
    },
    '.menuItemName': {
      textAlign: 'center',
      width: 'fit-content',
      margin: '0 auto 2rem',
      borderBottom: 'dotted 2px',
      borderColor: 'primary',
      paddingRight: '0rem',
    },
    '.menuItemDescription': {
      textAlign: 'center',
      fontSize: ['1rem', '', '1rem'],
      fontStyle: 'italic',
      width: '100%',
      lineHeight: ['1.5', '', '2'],
      opacity: '0.75',
      letterSpacing: '1px',
    },
    '.slick-slide': {
      maxHeight: '310px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
      MsOverflowStyle: 'none',
    },
  },

  // ? =======  About page  ========

  // aboutPageAboutSection: {
  //   '.title': {
  //     variant: 'customVariants.italianBorderBottom',
  //     color: 'secondary',
  //   },
  // },

  aboutPageTextSection: {
    padding: '1rem',
    '.section': {
      maxWidth: '1000px',
      textAlign: 'left',
      alignItems: 'flex-start',
    },
    '.title': {
      variant: 'customVariants.italianBorderBottom',
      fontSize: '2.5rem',
    },
  },

  aboutPageSideBySide: {
    maxWidth: '1000px',
    margin: '0 auto',
    '.content': {
      padding: ['1rem', '', '1rem 2rem 1rem 0rem'],
    },
    '.title': {
      variant: 'customVariants.italianBorderBottom',
      fontSize: '2.5rem',
    },
  },

  // ? =======  Events page  ========

  eventsSection: {
    '.modalContentContainer': {
      backgroundColor: 'light',
    },
  },

  locationMap: {
    order: '3',
    '.title': {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '', '2.5rem'],
      color: 'secondary',
      variant: 'customVariants.italianBorderBottom',
    },
    '.contactDetails-container, .hoursContainer ': {
      opacity: '0.9',
    },
    '.content_container': {
      backgroundColor: '#fff7e7',
    },
    '.timeBlocks': {
      width: 'auto',
    },
  },
  gallery: {
    '.albumImage': {
      borderTop: '2px solid',
      borderBottom: '2px solid',
      borderColor: 'secondary',
    },
    '.albumTitle': {
      color: 'text',
    },
  },

  contactForm: {
    padding: '3rem 1rem',
    order: '4',
    backgroundColor: '#f2f2f2',
    '.title': {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '', '2.5rem'],
      color: 'secondary',
      variant: 'customVariants.italianBorderBottom',
    },
    '.subtitle': {
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '2rem',
    },
    'form input, form textarea': {
      border: 'none',
      borderBottom: '2px solid',
      borderColor: 'primary',
      borderRadius: '0px',
    },
  },

  menu: {
    maxWidth: 'unset',
    color: 'black',
    '.menu > .menuContainer > .menuSectionTitle': {
      display: 'none',
    },
    '.allInContainerWrapper': {},
    '.allInContainer': {
      maxWidth: '1200px',
      margin: '0 auto',
    },
    '.menuContainer': {
      paddingBottom: '0rem',
    },

    '.menuItemContentContainer': {
      margin: '0rem',
    },
    '.menuItemName': {
      fontFamily: 'Lato, sans-serif',
      fontWeight: '700',
      marginBottom: '0.25rem',
    },
    '.menuItemDescription': {
      fontFamily: 'Lato, sans-serif',
      fontStyle: 'italic',
      fontWeight: '300',
    },
    '.menuSectionTitle': {
      // textShadow: '2px 3px grey',
      width: 'fit-content',
      margin: '2rem auto 2rem',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
      variant: 'customVariants.italianBorderBottom',
    },
    '.menuSectionDescription': {
      fontSize: '1.1rem',
      lineHeight: '1.5',
      maxWidth: '900px',
      margin: '0 auto 2rem',
      whiteSpace: 'pre',
    },
    '.variantContainer': {
      justifyContent: 'space-between',
    },
  },

  cateringMenu: {
    variant: 'customVariants.menu',
  },

  cateringPageIntro: {
    padding: '2rem 1rem 1rem',
  },
};
