export default {
  shoutSectionV3: {
    container: { flexWrap: ['wrap', '', '', 'nowrap'] },
    title: {
      backgroundColor: 'primary',
      padding: '0.5rem',
      color: 'light',
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      order: ['', '', '1'],
    },
    text: {
      fontSize: '1.5rem',
      padding: '1rem 0.5rem',
      order: ['', '', '4'],
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'secondary',
      width: ['', '', '70%'],
      fontWeight: 'bold',
      textDecoration: 'uppercase',
    },
    date: {
      backgroundColor: 'secondary',
      padding: '0.5rem',
      color: 'light',
      maxWidth: '130px',
      display: 'flex',
      alignItems: 'center',
      order: ['', '', '2'],
    },
    imageContainer: {
      position: 'relative',
      width: ['100%', '100%', '20%'],
      order: ['', '', '3'],
      img: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    imageFill: {
      paddingBottom: '70%',
    },
    shoutCTAS: {
      flexWrap: 'wrap',
      transition: 'all ease-in-out 0.3s',
      width: ['100%', '', 'auto'],
      order: ['', '', '5'],
    },
    shoutCTA: {
      variant: 'buttons.primary',
      fontSize: ['1rem', '1rem'],
      transition: 'all ease-in-out 0.3s',
      width: '50%',
      flexGrow: '1',
      backgroundColor: 'primary',
      color: 'light',
      textAlign: 'center',
      writingMode: ['', '', 'tb'],
      // border: 'solid 1px white',
      padding: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      ':hover': {
        color: 'secondary',
      },
    },
  },
}
