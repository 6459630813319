const variantName = 'navigationV2a'

export default {
  [variantName]: {
    container: {
      position: 'fixed',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1100',
      boxSizing: 'border-box',
      height: ['75px', '75px', '75px', 'unset'],
      padding: ['0.5rem', '', '0px'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'flex-end',
      background:
        'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.75) 50%, rgba(0,0,0,0) 100%);',
    },

    containerScrolled: {
      variant: `${variantName}.container`,
      backgroundColor: ['dark'],
    },

    locationSwitcher: {
      backgroundColor: '',
      position: ['', '', '', 'fixed'],
      right: ['', '', '', '0'],
      zIndex: '2000',
    },

    logo: {
      position: ['absolute', '', '', ''],
      top: ['0', '', '', '0px'],
      left: ['50%', '', '', '4'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      alignSelf: 'top',
      zIndex: '2000',
      transition: 'all ease-in-out 0.3s',
      img: {
        maxHeight: '100px',
      },
    },

    logoScrolled: {
      variant: `${variantName}.logo`,
      maxHeight: ['100%', '100px'],
      textAlign: ['center', '', '', 'left'],
      left: ['50%', '', '', '4'],
      top: ['0px', '', '', '0px'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      height: ['100%'],
      display: ['', '', '', 'inline'],
      img: {
        height: ['100%'],
        padding: 0,
      },
    },

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px',
      },
    },
    hamburgerOpen: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        position: 'absolute',
        boxShadow: 'none',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px',
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) ',
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)',
      },
    },
    navMenu: {
      position: ['absolute', 'absolute', 'absolute', 'unset'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['center'],
      height: ['100vh', '100vh', '100vh', 'unset'],
      backgroundColor: ['primary', 'primary', 'primary', 'primary'],
      top: '0px',
      zIndex: '1100',
      width: ['100%', '100%', '100%', '100%'],
      minWidth: '250px',
      left: -'120%',
      transition: 'all ease-in-out 0.5s',
      alignItems: ['center', 'center', 'center', 'center'],
      display: 'flex',
      flexDirection: ['column', 'column', 'column', 'row'],
      listStyleType: 'none',
      padding: ['110px 0.5rem 90px', '', '', '0.5rem'],
      borderTop: '3px solid',
      borderColor: 'primary',
      fontSize: ['1.5rem', '', '', '1rem'],
      fontWeight: '400',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      color: 'light',
    },
    navItem: {
      zIndex: '100',
      marginLeft: ['0px', '', '0px'],
      margin: ['0px 0px 0.5rem', '', '', '1rem 0.75rem'],
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      fontFamily: 'inherit',
      '&:hover': {
        color: 'inherit',
        filter: 'brightness(80%)',
      },
      a: {
        textDecoration: 'none',
      },
    },
    socialIconsContainer: {
      marginTop: '1rem',
      display: ['', '', '', 'none'],
    },
    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative',
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap',
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      position: ['', '', '', 'absolute'],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: 'primary',
    },
  },
}
